import { serialize, parse } from "cookie";

const TOKEN_NAME = "tindle_publication";

export const MAX_AGE = 60 * 60 * 8;

const gaRegions = {
  ["wales"]: "G-JQKNCXMZ5K",
  ["somerset"]: "G-9FTEDZC2V2",
  ["cornwall"]: "G-MT368J6WEV",
  ["herefordshire"]: "G-YBW55LVJ0Z",
  ["surrey"]: "G-CBLXTB7HYM",
  ["devon"]: "G-BYVS4ZGQN7",
  ["iom"]: "G-ER9V86G9NL",
};

export const WokingAndDaily = {
  advertising_links: {
    tel: "01483 802700",
    email: "info@wokingnewsandmail.org",
  },
  region: gaRegions["surrey"] || "",
  jobs_number: "01483 802700",
  public_notices: "https://publicnoticeportal.uk/woking-news-and-mail",
  your_story: "editor@wokingnewsandmail.org",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  publications: [
    {
      PublicationName: "Woking News & Mail",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=03ffd037-0ce2-42b5-8a7f-89e1c063d3fb",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=390&eid=7e1cd803-e706-40ab-a3d1-21c5ba3091ac",
    },
    {
      PublicationName: "Chobham News & Mail",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=19c8f526-0c7d-41fe-858e-19bfa02f86e4",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=390&eid=110f8db1-829a-436a-aeeb-e61d895971ce",
    },
  ],
  publication: "wokingnewsandmail.co.uk",
  g4_tracking_id: "G-SF3FNPYC26",
  gtm_id: "GTM-TKT77VF",
  twitter: "woking_newsmail",
  facebook: "WokingNewsMail",
  pub: "Woking News",

  web: "holsworthy-today.co.uk",
  url: "www.wokingnewsandmail.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/wokingnewsandmail",

  contact: `
Woking News & Mail,
16 Lower Guildford Road,
Woking,
GU21 2EG,
Tel: 01483 802700,
Email: info@wokingnewsandmail.org`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dCornwall%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=20",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=21",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Woking News and Mail - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Woking and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU21 2EG",
  mail_chimp: "&PUB=Holsworthy Post",
  //p-uGUachD16z0h6
  quantcast: "p-uGUachD16z0h6",
};

export const Holsworth = {
  advertising_links: {
    tel: "01566 772424",
    email: "holsworthy@thepost.uk.com",
  },
  region: gaRegions["cornwall"] || "",
  jobs_number: "01566 772424",
  public_notices: "http://southwest.epublicnotices.co.uk//",
  your_story: "news@thepost.uk.com",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_cornwall-uk/browse",
  publications: [
    {
      PublicationName: "Cornish & Devon Post",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
    },
    {
      PublicationName: "Holsworthy Post",
      PublicationLink:
        "https://edition.pagesuite.com/launch.aspx?pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
    },
    {
      PublicationName: "Bude and Stratton Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
    },
  ],
  publication: "holsworthy-today.co.uk",
  ga_tracking_id: "UA-65066581-1",
  g4_tracking_id: "G-GFFE7GP8B6",
  gtm_id: "GTM-PDS6GPJ",
  twitter: "ThePostSeries",
  facebook: "ThePostSeries",
  pub: "Holsworthy Post",

  web: "holsworthy-today.co.uk",
  url: "www.holsworthy-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/cornish_and_devon_post",
  contact: `Tindle House,
Westgate Street,
Launceston,
Cornwall, England,
PL15 7AL
Tel: 01566 778213
Email: news@thepost.uk.com`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dCornwall%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=20",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=21",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Holsworthy Post - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Holsworthy and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX22 6DJ",
  mail_chimp: "&PUB=Holsworthy Post",

  quantcast: "p-uGUachD16z0h6",
};

export const CornishDevonPost = {
  advertising_links: {
    tel: "01566 772424  ",
    email: "launceston@thepost.uk.com",
  },
  region: gaRegions["cornwall"] || "",
  jobs_number: "01566 772424",
  public_notices: "https://publicnoticeportal.uk/cornish-and-devon-post-series",
  your_story: "news@thepost.uk.com",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_cornwall-uk/browse",
  publications: [
    {
      PublicationName: "Cornish & Devon Post",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
    },
    {
      PublicationName: "Holsworthy Post",
      PublicationLink:
        "https://edition.pagesuite.com/launch.aspx?pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
    },
    {
      PublicationName: "Bude and Stratton Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
    },
  ],

  publication: "thepost.uk.com",
  ga_tracking_id: "UA-65066581-1",
  g4_tracking_id: "G-1PJLV07R5P",
  gtm_id: "GTM-WF3QDBR",
  twitter: "ThePostSeries",
  facebook: "ThePostSeries",
  pub: "Cornish & Devon Post",

  web: "thepost.uk.com",
  url: "www.thepost.uk.com/",
  area: "",
  media_force: "/154725070,22694784101/cornish_and_devon_post",
  contact: `Tindle House,
Westgate Street,
Launceston,
Cornwall, England,
PL15 7AL
Tel: 01566 778213
Email: news@thepost.uk.com`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dCornwall%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=20",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=21",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Cornish & Devon Post - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Cornwall, North and West Devon and your local area.",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "PL15 7AL",
  mail_chimp: "&PUB=Cornish Devon Post",
  quantcast: "p-uGUachD16z0h6",
};

export const BudeStrattonPost = {
  advertising_links: {
    tel: "01566 772424  ",
    email: "news@thepost.uk.com",
  },
  public_notices: "http://southwest.epublicnotices.co.uk//",
  your_story: "news@thepost.uk.com",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_cornwall-uk/browse",
  jobs_number: "01566 772424",
  publications: [
    {
      PublicationName: "Cornish & Devon Post",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=e2e9e2a4-1315-433f-9b6d-ea03adc6dbc1",
    },
    {
      PublicationName: "Holsworthy Post",
      PublicationLink:
        "https://edition.pagesuite.com/launch.aspx?pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=63ba1682-ec70-43fa-bc69-49f92ab306be",
    },
    {
      PublicationName: "Bude and Stratton Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=39ddd16b-7a0d-44d2-bf4c-5aa3d999490c",
    },
  ],
  publication: "bude-today.co.uk",
  ga_tracking_id: "UA-65066581-1",
  g4_tracking_id: "G-5KGT8WH6CK",
  gtm_id: "GTM-WB9TVVS",
  twitter: "ThePostSeries",
  facebook: "ThePostSeries",
  pub: "Bude & Stratton Post",

  web: "bude-today.co.uk",
  url: "www.bude-today.co.uk/",
  area: "",
  region: gaRegions["cornwall"] || "",
  media_force: "/154725070,22694784101/cornish_and_devon_post",
  contact: `Tindle House,
Westgate Street,
Launceston,
Cornwall, England,
PL15 7AL
Tel: 01566 778213
Email: news@thepost.uk.com`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dCornwall%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=77",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=78",
  },
  page_suite: {
    publication_name: "Bude & Stratton Post",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Bude & Stratton Post - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Bude, Stratton and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX23 8LD",
  mail_chimp: "&PUB=Bude Stratton Post",

  quantcast: "p-uGUachD16z0h6",
};

export const TheCornishTimes = {
  advertising_links: {
    tel: "01579 342174  ",
    email: "advertising@cornish-times.co.uk",
  },
  region: gaRegions["cornwall"] || "",
  jobs_number: "01579 342174",
  public_notices: "https://publicnoticeportal.uk/cornish-times-series",
  your_story: "editorial@cornish-times.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_cornwall-uk/browse",
  publications: [
    {
      PublicationName: "The Cornish Times",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=46671691-92a9-4b81-9c56-08074e88b587",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=46671691-92a9-4b81-9c56-08074e88b587",
    },
  ],
  special_editions: [
    {
      PublicationName: "Christmas Carol Book 2017",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A432jf/LiskCarolBook/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3930266%252FLisk-Carol-Book%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/266/485843ec3/thumb/page1.jpg",
    },
    {
      PublicationName: "Cornish Visitor Guide 2018",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43bch/cornishvisitor/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3934652%252Fcornish-visitor%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/4652/c8048770c/thumb/page1.jpg",
    },
    {
      PublicationName: "Events Special",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43aa5/eventsspecial/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3934141%252Fevents-special%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/4141/88c3c77f7/thumb/page1.jpg",
    },
    {
      PublicationName: "Farming",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A439lb/Farming/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3933783%252FFarming%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/3783/651110112/thumb/page1.jpg",
    },
    {
      PublicationName: "Great Trethew Vintage Rally",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A42zit/GreatTrethew/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3928544%252FGreat-Trethew%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/392/8544/8a0073eeb/thumb/page1.jpg",
    },
    {
      PublicationName: "Liskeard Show",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A439lb/Farming/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3933783%252FFarming%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/3783/651110112/thumb/page1.jpg",
    },
  ],
  publication: "cornish-times.co.uk",
  ga_tracking_id: "UA-65132209-1",
  g4_tracking_id: "G-J70X2DL02Z",
  gtm_id: "GTM-KHB5J3M",
  twitter: "cornishtimes",
  facebook: "TheCornishTimes",
  pub: "Cornish times",

  web: "cornish-times.co.uk",
  url: "www.cornish-times.co.uk/",
  area: "",
  media_force: "154725070,22694784101/cornish-times",
  contact: `The Tindle Suite,
Webbs House,
Liskeard,
Cornwall, England,
PL14 6AH
Tel: 01579 342174
Email: editorial@cornish-times.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dDevon%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=29",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=30",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "The Cornish Times - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Cornwall and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "PL14 6AH",
  mail_chimp: "&PUB=The Cornish Times",

  quantcast: "p-uGUachD16z0h6",
};

export const MidsomerNorton = {
  advertising_links: {
    tel: "01761 258030",
    email: "contactus@mnrjournal.co.uk",
  },
  jobs_number: "01761 258030",
  public_notices:
    "https://publicnoticeportal.uk/midsomer-norton-radstock-and-district-journal",
  your_story: "contactus@mnrjournal.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_west_country-uk/browse",

  publications: [
    {
      PublicationName: "Chew Valley Gazette",
      PublicationLink:
        "https://edition.pagesuite.com/Launch.aspx?PBID=0c6ffc9f-2cc0-4a3d-a55c-83e3a7013a5d",

      PublicationThumb:
        "https://edition.pagesuite.com/get_image.aspx?newportal=true&w=120&pbid=0c6ffc9f-2cc0-4a3d-a55c-83e3a7013a5d",
    },
    {
      PublicationName: "The Midsomer Norton, Radstock & District Journal",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=295b5428-cf7a-4f01-82df-c628393df3ad",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=160&pbid=295b5428-cf7a-4f01-82df-c628393df3ad",
    },
  ],

  publication: "mnrjournal.co.uk",
  ga_tracking_id: "UA-65127841-1",
  g4_tracking_id: "G-B8TE5GCQK2",
  gtm_id: "GTM-NZSM672",
  twitter: "mnrjournal",
  facebook: "mnrjournal",
  pub: "The Midsomer Norton, Radstock & District Journal",

  web: "mnrjournal.co.uk",
  url: "www.mnrjournal.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/mnrjournal",
  region: gaRegions["somerset"] || "",
  contact: `Paulton House, Old Mills,
Paulton,
Bristol, UK,
BS39 7SX
Tel: 01761 258030
Email: contactus@mnrjournal.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSomerset%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=62",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=63",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Midsomer Norton, Radstock & District Journal with Chew Valley Gazette - local news at the heart of the community.",
  default_desc_seo:
    "Latest news, sport and what’s on in Midsomer Norton, Radstock, Chew Valley and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "BA3 2HQ",
  mail_chimp: "&PUB=MNR",

  quantcast: "p-uGUachD16z0h6",
};

export const WellingtonWeeklyNews = {
  advertising_links: {
    tel: "01823 664633",
    email: "ads@wellingtonweeklynews.co.uk",
  },
  jobs_number: "01984 632731",
  public_notices: "https://publicnoticeportal.uk/wellington-weekly-news",
  your_story: "edit@wellingtonweeklynews.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_west_country-uk/browse",
  publications: [
    {
      PublicationName: "Wellington Weekly News",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=b6a17d1c-d1a4-4547-9131-49a0411b213d",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=b6a17d1c-d1a4-4547-9131-49a0411b213d",
    },
  ],

  publication: "wellington-today.co.uk",
  ga_tracking_id: "UA-65130022-1",
  g4_tracking_id: "G-T7H7RYXK36",
  gtm_id: "GTM-KR3WSJC",
  twitter: "wellingtonweek",
  facebook: "wellingtonweeklynews",
  pub: "Wellington Weekly News",

  web: "wellington-today.co.uk",
  url: "www.wellington-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/wellington-today",
  region: gaRegions["somerset"] || "",
  contact: `26 High Street,
Wellington,
Somerset, England,
TA21 8RA
Tel: 01823 662439
Email: edit@wellingtonweeklynews.co.uk
`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSomerset%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=95",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=96",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Wellington Weekly News - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Wellington and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TA21 8AD",
  mail_chimp: "&PUB=Wellington Weekly",

  quantcast: "p-uGUachD16z0h6",
};

export const WestSomersetFreePress = {
  advertising_links: {
    tel: "01984 632731",
    email: "ads@wsfp.co.uk",
  },
  jobs_number: "01984 632731",
  region: gaRegions["somerset"] || "",
  public_notices: "https://publicnoticeportal.uk/west-somerset-free-press",
  your_story: "news@wsfp.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_west_country-uk/browse",
  publications: [
    {
      PublicationName: "West Somerset Free Press",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=ca990498-e0c7-4803-ac3c-813137b5bab8",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=ca990498-e0c7-4803-ac3c-813137b5bab8",
    },
  ],

  publication: "wsfp.co.uk",
  ga_tracking_id: "UA-65131919-1",
  g4_tracking_id: "G-NDP0B97ZTL",
  gtm_id: "GTM-57QN985",
  twitter: "wsfp_newsroom",
  facebook: "WestSomFreePress",
  pub: "West Somerset Free Press",

  web: "wsfp.co.uk",
  url: "www.wsfp.co.uk,",
  area: "",
  media_force: "/154725070,22694784101/wsfp",
  contact: `5 Long Street,
Williton,
Taunton,
Somerset, England,
TA4 4QN
Tel: 01984 632731
Email: news@wsfp.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSomerset%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=98",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=99",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "West Somerset Free Press - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in West Somerset and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TA4 4QH",
  mail_chimp: "&PUB=West Somerset Free Press",

  quantcast: "p-uGUachD16z0h6",
};

//iom
export const IOM = {
  advertising_links: {
    tel: "01624 695695",
    email: "ads@iomtoday.co.im",
  },
  jobs_number: "01624 695695",
  public_notices: null,
  region: gaRegions["iom"] || "",
  family_anouncements: "https://www.legacy.com/obituaries/iomtoday-uk/",

  special_editions: [
    {
      PublicationName: "Diversity",
      PublicationLink:
        "https://content.yudu.com/web/17nyu/0A44lxz/Diversity24/index.html",
      PublicationThumb:
        "https://gef.im/wp-content/uploads/2024/06/thumbnail_image001.jpg",
    },
    {
      PublicationName: "Womann",
      PublicationLink:
        "https://edition.pagesuite.com/launch.aspx?pbid=051f4a5a-e86c-481b-a803-26e4c0d108c2",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?newportal=true&w=600&pbid=051f4a5a-e86c-481b-a803-26e4c0d108c2",
    },

    {
      PublicationName: "Behind the Business",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk//launch.aspx?pbid=4f4f4d19-bbc1-4aee-b328-7e09baec9fc6",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=600&pbid=4f4f4d19-bbc1-4aee-b328-7e09baec9fc6",
    },
    {
      PublicationName: "Destination Isle Of Man",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=c7ec6c5f-a6be-4542-b2f9-ae49a7dc8828",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=c7ec6c5f-a6be-4542-b2f9-ae49a7dc8828",
    },
    {
      PublicationName: "Green Life",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=3b667b9a-bc2f-4401-a31c-9c1026cda6bd",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=600&pbid=3b667b9a-bc2f-4401-a31c-9c1026cda6bd",
    },
    {
      PublicationName: "Home",
      PublicationLink:
        "http://edition.pagesuite.com/launch.aspx?pbid=bbe0ed1f-45a4-4903-97a4-9226aa2f8e02",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=bbe0ed1f-45a4-4903-97a4-9226aa2f8e02",
    },
    // {
    //   PublicationName: "MANN",
    //   PublicationLink:
    //     "http://edition.pagesuite.com/launch.aspx?eid=82575766-4cb1-47b4-8e5f-9ac8b5e63c31",
    //   PublicationThumb:
    //     "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=00&eid=82575766-4cb1-47b4-8e5f-9ac8b5e63c31",
    // },

    //TT NEWS

    {
      PublicationName: "TT NEWS",
      PublicationLink:
        "https://subscriber.pagesuite.com/subscribe.aspx?t=2135&eid=7d8aad22-56d5-49a5-9a9f-18dfdd824e74",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?newportal=true&w=600&pbid=801e5276-f356-4228-b613-b3e4a21115f7",
    },
    // {
    //   PublicationName: "TT News Xtra 2020",
    //   PublicationLink:
    //     "https://subscriber.pagesuite-professional.co.uk/subscribe.aspx?source=4&eid=664f029b-b39b-44e6-bdf6-8ae8f51012fc",
    //   PublicationThumb:
    //     "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=a8c67c13-ab8e-4421-836c-d447facd3bd4",
    // },
    {
      PublicationName: "What's On",
      PublicationLink:
        "https://edition.pagesuite.com/launch.aspx?pbid=d6f788f3-586d-4924-96f9-b35537a3b713",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk//get_image.aspx?newportal=true&w=600&pbid=d6f788f3-586d-4924-96f9-b35537a3b713",
    },
  ],
  publication: "iomtoday.co.im",
  ga_tracking_id: "UA-85994090-1",
  g4_tracking_id: "G-NLQKN20LZ2",
  gtm_id: "GTM-PCG8GXT",
  poool_app_id:
    "utBPIFqYbqwW4IfxahFyPNMo7zjsJqc6oI3XkY7fxyGWkyuc79N62rqC84C8THBU",
  twitter: "isleofmantoday",
  facebook: "iomnewspapers/",
  pub: "Isle of Man Today",

  web: "iomtoday.co.im",
  url: "www.iomtoday.co.im",
  area: "",
  media_force: "/154725070,22694784101/iomtoday",
  your_story: "newsdesk@iomtoday.co.im",

  contact: `Media Isle of Man,
  18 Finch Road,
  Douglas,
  Isle of Man,
  IM1 2PT,
  Tel: 01624 695695,
  Email: newsdesk@iomtoday.co.im`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dIsle%2bof%2bMan%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=56",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=57",
  },
  page_suite: {
    publication_name: "Isle of Man Courier",
    Publication_link:
      "http://edition.pagesuite.com/html5/reader/production/default.aspx?pubname=&pubid=29507a1f-e0fb-4f62-b03a-e67123597837",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=29507a1f-e0fb-4f62-b03a-e67123597837",
  },

  publications: [
    {
      PublicationName: "Isle of Man Courier",
      PublicationLink:
        "http://edition.pagesuite.com/html5/reader/production/default.aspx?pubname=&pubid=29507a1f-e0fb-4f62-b03a-e67123597837",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=29507a1f-e0fb-4f62-b03a-e67123597837",
    },

    {
      PublicationName: "Isle of Man Examiner",
      PublicationLink:
        "https://subscriber.pagesuite-professional.co.uk/subscribe.aspx?source=4&eid=61e9aa80-71c5-4064-9e4e-81d3ebcd38ee",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=b72bd3dc-f8c3-41f0-ae16-b427d5e6d9fc",
    },
    {
      PublicationName: "Manx Independent",
      PublicationLink:
        "https://subscriber.pagesuite-professional.co.uk/subscribe.aspx?source=4&eid=f97f3192-a627-43b8-8fd9-d77459bdecf0",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=180&pbid=3b48f09d-dd37-41f2-8f8d-91a4f3f0f96b",
    },
  ],

  default_Seo: "Isle of Man Today - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Isle of Man and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX17 2BT",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};

//devon

export const CreditonCourier = {
  advertising_links: {
    tel: "01363 774263 ",
    email: "sales@creditoncourier.co.uk",
  },
  region: gaRegions["devon"] || "",
  jobs_number: "01363 774263",
  public_notices: "https://publicnoticeportal.uk/crediton-courier",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Crediton Courier",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=cddd42cc-07ef-4a58-889b-e9d4124b8b12",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=cddd42cc-07ef-4a58-889b-e9d4124b8b12",
    },
  ],
  special_editions: null,
  publication: "creditoncourier.co.uk",
  ga_tracking_id: "UA-65070252-1",
  g4_tracking_id: "G-JYEBWMJYWP",
  gtm_id: "GTM-PHB5HP3",
  twitter: "creditoncourier",
  facebook: "creditoncouriernewspaper",
  pub: "Crediton Courier",

  web: "creditoncourier.co.uk",
  url: "www.creditoncourier.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/creditoncourier",
  your_story: "editor@creditoncourier.co.uk",

  contact: `Crediton Country Courier,
102 High Street,
Crediton,
Devon, England,
EX17 3LF
Tel: 01363 774263
Email: editor@creditoncourier.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=35",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=36",
  },
  page_suite: {
    publication_name: "Crediton Courier",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Crediton Courier - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Crediton and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX17 2BT",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const OkehamptonTimes = {
  advertising_links: {
    tel: "01822 613666  ",
    email: "http://southwest.epublicnotices.co.uk/",
  },
  jobs_number: "01837 53641",
  public_notices: "https://wales.epublicnotices.co.uk/",
  your_story: "editorial@tavistock-today.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Okehampton Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6c536098-f93f-4533-a7a9-19e8056760ed",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=6c536098-f93f-4533-a7a9-19e8056760ed",
    },
    {
      PublicationName: "Tavistock Times Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=f168a8f3-434e-4b53-bec5-f5e6786ea452",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=f168a8f3-434e-4b53-bec5-f5e6786ea452",
    },
  ],
  special_editions: null,
  publication: "okehampton-today.co.uk",
  ga_tracking_id: "UA-65128205-1",
  g4_tracking_id: "G-MXXCFG29BR",
  gtm_id: "GTM-T5P75QT",
  twitter: "okeytimes",
  facebook: "Okehampton.Times",
  pub: "Okehampton Times",
  region: gaRegions["devon"] || "",

  web: "okehampton-today.co.uk",
  url: "www.okehampton-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/tavistock-today",
  contact: `42 Brook Street,
  Tavistock,
  Devon, England,
  PL19 0HE
  Tel: 01822 613666
  Email: editorial@tavistock-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dPembrokeshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=83",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=84",
  },
  page_suite: {
    publication_name: "Okehampton Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Okehampton Times - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Okehampton and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX20 1AA",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const TamarValleyTimes = {
  advertising_links: {
    tel: "01822 613666  ",
    email: "lianne.hunt@tavistock-today.co.uk",
  },
  jobs_number: "01822 613666",
  public_notices: "http://southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  your_story: "editorial@tavistock-today.co.uk",
  publications: [
    {
      PublicationName: "Tavistock Times Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=f168a8f3-434e-4b53-bec5-f5e6786ea452",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=f168a8f3-434e-4b53-bec5-f5e6786ea452",
    },
    {
      PublicationName: "Okehampton Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6c536098-f93f-4533-a7a9-19e8056760ed",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=6c536098-f93f-4533-a7a9-19e8056760ed",
    },
  ],
  special_editions: null,
  publication: "tavistock-today.co.uk",
  ga_tracking_id: "UA-65128205-1",
  g4_tracking_id: "G-BPBMHY3XRS",
  gtm_id: "GTM-KJ63B3J",
  twitter: "tavistocktimes",
  facebook: "tavistock.times",
  pub: "Tavistock Times Gazette",
  region: gaRegions["devon"] || "",

  web: "tavistock-today.co.uk",
  url: "www.tavistock-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/tavistock-today",
  contact: `42 Brook Street,
Tavistock,
Devon, England,
PL19 0HE
Tel: 01822 613666
Email: editorial@tavistock-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=83",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=84",
  },
  page_suite: {
    publication_name: "Tavistock Times Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Tavistock Times Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Tavistock and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "PL19 0HD",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const TotnesTimes = {
  advertising_links: {
    tel: "01548 853101 ",
    email: "charlene.wildman@southhams-today.co.uk",
  },
  jobs_number: "01548 853101",
  public_notices: "http://southwest.epublicnotices.co.uk//",
  your_story: "newsdesk@southhams-today.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Totnes Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2745d044-253a-4566-a576-1e59c358bf01",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=2745d044-253a-4566-a576-1e59c358bf01",
    },
    {
      PublicationName: "Dartmouth Chronicle",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=942dc439-3d1f-455f-bc0d-ffd600a28393",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=942dc439-3d1f-455f-bc0d-ffd600a28393",
    },
    {
      PublicationName: "Ivybridge & South Brent Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
    },
    {
      PublicationName: "Kingsbridge & Salcombe Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
    },
    {
      PublicationName: "Plympton, Plymstock and Ivybridge News",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=78685792-66ee-40d4-b2b4-5a490b0348ae",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=78685792-66ee-40d4-b2b4-5a490b0348ae",
    },
  ],

  special_editions: [
    {
      PublicationName: "Food & Drink Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qfl/FoodGuide19Cropped/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942038%252FFood-Guide-19-Cropped%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2023/108b85ab5/thumb/page1.jpg",
    },
    {
      PublicationName: "Holiday Times",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43zw6/HolidayTimes2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3945815%252FHoliday-Times-2020%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/5815/aa2272e48/thumb/page1.jpg",
    },

    {
      PublicationName: "Homes & Gardens",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43p1h/HomesampGardens/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941419%252FHomes---Gardens%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1419/125139cfe/thumb/page1.jpg",
    },
    {
      PublicationName: "South Hams Directory",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43u26/Directory2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3943584%252FDirectory-2020%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/3575/6f52f3d08/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ebr/WeddingGuide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3936144%252FWedding-Guide%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/6144/2e9efb7ef/thumb/page1.jpg",
    },
  ],

  publication: "totnes-today.co.uk",
  ga_tracking_id: "UA-65085817-1",
  g4_tracking_id: "G-CBV6HJRRXJ",
  gtm_id: "GTM-MSDF7PN",
  twitter: "TotnesTimes",
  facebook: "totnestimes",
  pub: "Totnes Times",

  web: "totnes-today.co.uk",
  url: "www.totnes-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/southhams-today",
  region: gaRegions["devon"] || "",

  contact: `Tindle House,
101-103 Fore Street,
Kingsbridge,
Devon, England,
TQ7 1DA
Tel: 01548 856353
Email: tom.ladds@southhams-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=77",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=78",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Totnes Times - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Totnes and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ9 5QH",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};

export const SouthHamsGazette = {
  advertising_links: {
    tel: "01548 853101 ",
    email: "charlene.wildman@southhams-today.co.uk",
  },
  jobs_number: "01548 853101",
  public_notices: "https://publicnoticeportal.uk/south-hams-newspapers-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  your_story: "Newsdesk@southhams-today.co.uk",
  publications: [
    {
      PublicationName: "Ivybridge & South Brent Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
    },
    {
      PublicationName: "Totnes Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2745d044-253a-4566-a576-1e59c358bf01",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=2745d044-253a-4566-a576-1e59c358bf01",
    },
    {
      PublicationName: "Dartmouth Chronicle",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=942dc439-3d1f-455f-bc0d-ffd600a28393",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=942dc439-3d1f-455f-bc0d-ffd600a28393",
    },
    {
      PublicationName: "Kingsbridge & Salcombe Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
    },
  ],
  special_editions: [
    {
      PublicationName: "Food & Drink Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qfl/FoodGuide19Cropped/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942038%252FFood-Guide-19-Cropped%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2023/108b85ab5/thumb/page1.jpg",
    },
    {
      PublicationName: "Holiday Times",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43zw6/HolidayTimes2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3945815%252FHoliday-Times-2020%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/5815/aa2272e48/thumb/page1.jpg",
    },

    {
      PublicationName: "Homes & Gardens",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43p1h/HomesampGardens/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941419%252FHomes---Gardens%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1419/125139cfe/thumb/page1.jpg",
    },
    {
      PublicationName: "South Hams Directory",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43u26/Directory2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3943584%252FDirectory-2020%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/3575/6f52f3d08/thumb/page1.jpg",
    },

    {
      PublicationName: "Wedding Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ebr/WeddingGuide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3936144%252FWedding-Guide%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/6144/2e9efb7ef/thumb/page1.jpg",
    },
  ],
  publication: "southhams-today.co.uk",
  ga_tracking_id: "UA-65085817-1",
  g4_tracking_id: "G-SJWYGQ763R",
  gtm_id: "GTM-PXJ3K96",
  twitter: "NewsSouthHams",
  facebook: "SouthHamsNews",
  pub: "South Hams Gazette",
  region: gaRegions["devon"] || "",

  web: "southhams-today.co.uk",
  url: "www.southhams-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/southhams-today",
  contact: `Tindle House,
101-103 Fore Street,
Kingsbridge,
Devon, England,
TQ7 1DA
Tel: 01548 856353
Email: tom.ladds@southhams-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=74",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=75",
  },
  page_suite: {
    publication_name: "South Hams Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "South Hams Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in South Hams and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ9 5NE",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};

export const Voice = {
  advertising_links: {
    tel: null,
    email: "news@voicenewspapers.co.uk",
  },
  jobs_number: "01566 772424",
  public_notices: "https://publicnoticeportal.uk/voice-newspapers-series",
  your_story: "news@voicenewspapers.co.uk",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_cornwall-uk/browse",
  publication: "voicenewspapers.co.uk/",
  g4_tracking_id: "G-WF7J277X0S",
  gtm_id: "GTM-KN4TS95",
  // twitter: "ThePostSeries",
  // facebook: "ThePostSeries",
  pub: "Voice",
  web: "voicenewspapers.co.uk/",
  url: "www.voicenewspapers.co.uk/",
  area: "",
  region: gaRegions["devon"] || "",

  contact: `Voice Newspapers,
The Tindle Suite,
Webb’s House,
Liskeard,
PL14 6AH,
news@voicenewspapers.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dCornwall%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=20",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=21",
  },
  page_suite: {
    publication_name: "Totnes Times",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "The Voice - local news at the heart of the community",
  default_desc_seo:
    "Local news, sport and information for communities across Cornwall",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX22 6DJ",
  mail_chimp: "&PUB=Voice",
  facebook: {
    ["bodmin-voice"]: "https://www.facebook.com/bodminvoice",
    ["newquay-voice"]: "https://www.facebook.com/NewQVoice",
    ["penzance-voice"]: "https://www.facebook.com/PenzanceVoice",
    ["saltash-voice"]: "https://www.facebook.com/SaltashVoice",
    ["st-austell-voice"]: "https://www.facebook.com/StAustellVoice",
    ["truro-voice"]: "https://www.facebook.com/trurovoice",
    ["liskeard-voice"]:
      "https://www.facebook.com/profile.php?id=100077297058809",
    ["heartlands-voice"]:
      "https://www.facebook.com/profile.php?id=100065153095414",
  },

  twitter: {
    ["bodmin-voice"]: "@BodminVoice",
    ["newquay-voice"]: "@Newquay_Voice",
    ["penzance-voice"]: "@Penzance_Voice",
    ["saltash-voice"]: "@SaltashVoice",

    ["st-austell-voice"]: "@StAustellVoice",
    ["truro-voice"]: "@truro_voice",
    ["liskeard-voice"]: "@LiskeardVoice",
    ["heartlands-voice"]: "@HeartlandsVoice",
  },
  quantcast: "p-uGUachD16z0h6",
  publications: [
    {
      PublicationName: "Newquay Voice",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=024b2a46-6db5-4e9d-af48-6304d81aa876",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=024b2a46-6db5-4e9d-af48-6304d81aa876",
    },
    {
      PublicationName: "St Austell Voice",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=5f15750d-d00b-460b-9545-b8b14631cff9",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=5f15750d-d00b-460b-9545-b8b14631cff9",
    },
    {
      PublicationName: "Truro Voice",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=e564cbcd-835e-4ed3-b533-d77627022cf7",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=e564cbcd-835e-4ed3-b533-d77627022cf7",
    },
  ],
  media_force: "/154725070,22694784101/voicenewspapers",
};

export const KingsbridgeSalcombeGazette = {
  advertising_links: {
    tel: "01548 853101 ",
    email: "charlene.wildman@southhams-today.co.uk",
  },
  jobs_number: "01548 853101",
  your_story: "Newsdesk@southhams-today.co.uk",
  public_notices: "http://southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  region: gaRegions["devon"] || "",

  publications: [
    {
      PublicationName: "Totnes Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2745d044-253a-4566-a576-1e59c358bf01",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=2745d044-253a-4566-a576-1e59c358bf01",
    },
    {
      PublicationName: "Dartmouth Chronicle",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=942dc439-3d1f-455f-bc0d-ffd600a28393",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=942dc439-3d1f-455f-bc0d-ffd600a28393",
    },
    {
      PublicationName: "Ivybridge & South Brent Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
    },
    {
      PublicationName: "Kingsbridge & Salcombe Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
    },
    {
      PublicationName: "Plympton, Plymstock and Ivybridge News",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=78685792-66ee-40d4-b2b4-5a490b0348ae",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=78685792-66ee-40d4-b2b4-5a490b0348ae",
    },
  ],
  special_editions: [
    {
      PublicationName: "Food & Drink Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qfl/FoodGuide19Cropped/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942038%252FFood-Guide-19-Cropped%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2023/108b85ab5/thumb/page1.jpg",
    },

    {
      PublicationName: "Holiday Times",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43zw6/HolidayTimes2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3945815%252FHoliday-Times-2020%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/5815/aa2272e48/thumb/page1.jpg",
    },
    {
      PublicationName: "Homes & Gardens",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43p1h/HomesampGardens/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941419%252FHomes---Gardens%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1419/125139cfe/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ebr/WeddingGuide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3936144%252FWedding-Guide%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/6144/2e9efb7ef/thumb/page1.jpg",
    },

    {
      PublicationName: "South Hams Directory",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43u26/Directory2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3943584%252FDirectory-2020%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/3575/6f52f3d08/thumb/page1.jpg",
    },
  ],
  publication: "kingsbridge-today.co.uk",
  ga_tracking_id: "UA-65085817-1",
  g4_tracking_id: "G-J07SVX3HDJ",
  gtm_id: "GTM-5W45WVK",
  twitter: "NewsSouthHams",
  facebook: "KBSalcGazette",
  pub: "Kingsbridge & Salcombe Gazette",

  web: "kingsbridge-today.co.uk",
  url: "www.kingsbridge-today.co.uk",
  area: "",
  media_force: "/154725070,22694784101/southhams-today",
  contact: `Tindle House,
101-103 Fore Street,
Kingsbridge,
Devon, England,
TQ7 1DA
Tel: 01548 856353
Email: tom.ladds@southhams-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=77",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=78",
  },
  page_suite: {
    publication_name: "Kingsbridge & Salcombe Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Kingsbridge & Salcombe Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Kingsbridge & Salcombe and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ7 1DZ",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};

export const IvybridgeSouthBrentGazette = {
  advertising_links: {
    tel: "01548 853101 ",
    email: "charlene.wildman@southhams-today.co.uk",
  },
  jobs_number: "01548 853101",
  your_story: "Newsdesk@southhams-today.co.uk",
  public_notices: "http://southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Ivybridge & South Brent Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bd6d81c6-7ed4-4d1c-9352-ed3f74e4aef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
    },
    {
      PublicationName: "Dartmouth Chronicle",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=942dc439-3d1f-455f-bc0d-ffd600a28393",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=942dc439-3d1f-455f-bc0d-ffd600a28393",
    },
    {
      PublicationName: "Kingsbridge & Salcombe Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
    },
    {
      PublicationName: "Plympton, Plymstock and Ivybridge News",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=78685792-66ee-40d4-b2b4-5a490b0348ae",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=78685792-66ee-40d4-b2b4-5a490b0348ae",
    },
    {
      PublicationName: "Totnes Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2745d044-253a-4566-a576-1e59c358bf01",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=2745d044-253a-4566-a576-1e59c358bf01",
    },
  ],
  special_editions: [
    {
      PublicationName: "Food & Drink Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qfl/FoodGuide19Cropped/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942038%252FFood-Guide-19-Cropped%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2023/108b85ab5/thumb/page1.jpg",
    },

    {
      PublicationName: "Holiday Times",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43zw6/HolidayTimes2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3945815%252FHoliday-Times-2020%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/5815/aa2272e48/thumb/page1.jpg",
    },
    {
      PublicationName: "Homes & Gardens",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43p1h/HomesampGardens/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941419%252FHomes---Gardens%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1419/125139cfe/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ebr/WeddingGuide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3936144%252FWedding-Guide%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/6144/2e9efb7ef/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Planner",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43wnp/tenbyweddings20/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3944743%252Ftenby-weddings-20%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/4743/a1f117f7b/thumb/page1.jpg",
    },
    {
      PublicationName: "South Hams Directory",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43u26/Directory2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3943584%252FDirectory-2020%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/3575/6f52f3d08/thumb/page1.jpg",
    },
  ],
  publication: "ivybridge-today.co.uk",
  ga_tracking_id: "UA-65085817-1",
  g4_tracking_id: "G-PEKVMWPYXB",
  gtm_id: "GTM-TQMG8TD",
  twitter: "NewsSouthHams",
  facebook: "IvySouthBrentGazette",
  pub: "Ivybridge & South Brent Gazette",
  region: gaRegions["devon"] || "",

  web: "ivybridge-today.co.uk",
  url: "www.ivybridge-today.co.uk",
  area: "",
  media_force: "/154725070,22694784101/southhams-today",
  contact: `Tindle House,
101-103 Fore Street,
Kingsbridge,
Devon, England,
TQ7 1DA
Tel: 01548 856353
Email: tom.ladds@southhams-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=77",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=78",
  },
  page_suite: {
    publication_name: "Ivybridge & South Brent Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Ivybridge & South Brent Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Ivybridge & South Brent and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "PL21 0SZ",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};

export const DartmouthChronicle = {
  advertising_links: {
    tel: "01548 853101 ",
    email: "charlene.wildman@southhams-today.co.uk",
  },
  jobs_number: "01548 853101",
  your_story: "Newsdesk@southhams-today.co.uk",
  public_notices: "http://southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Dartmouth Chronicle",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=942dc439-3d1f-455f-bc0d-ffd600a28393",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=942dc439-3d1f-455f-bc0d-ffd600a28393",
    },

    {
      PublicationName: "Totnes Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2745d044-253a-4566-a576-1e59c358bf01",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=2745d044-253a-4566-a576-1e59c358bf01",
    },

    {
      PublicationName: "Ivybridge & South Brent Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=bd6d81c6-7ed4-4d1c-9352-ed3f7d4e4aef",
    },
    {
      PublicationName: "Kingsbridge & Salcombe Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=3dd30742-18ca-4719-a2f7-f6f2d0a24e7f",
    },
    {
      PublicationName: "Plympton, Plymstock and Ivybridge News",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=78685792-66ee-40d4-b2b4-5a490b0348ae",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=78685792-66ee-40d4-b2b4-5a490b0348ae",
    },
  ],
  special_editions: [
    {
      PublicationName: "Food & Drink Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qfl/FoodGuide19Cropped/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942038%252FFood-Guide-19-Cropped%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2023/108b85ab5/thumb/page1.jpg",
    },

    {
      PublicationName: "Holiday Times",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43zw6/HolidayTimes2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3945815%252FHoliday-Times-2020%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/5815/aa2272e48/thumb/page1.jpg",
    },
    {
      PublicationName: "Homes & Gardens",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43p1h/HomesampGardens/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941419%252FHomes---Gardens%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1419/125139cfe/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ebr/WeddingGuide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3936144%252FWedding-Guide%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/6144/2e9efb7ef/thumb/page1.jpg",
    },
    {
      PublicationName: "Wedding Planner",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43wnp/tenbyweddings20/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3944743%252Ftenby-weddings-20%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/4743/a1f117f7b/thumb/page1.jpg",
    },
    {
      PublicationName: "South Hams Directory",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43u26/Directory2020/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3943584%252FDirectory-2020%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/3575/6f52f3d08/thumb/page1.jpg",
    },
  ],
  publication: "dartmouth-today.co.uk",
  ga_tracking_id: "UA-65085817-1",
  g4_tracking_id: "G-5R4S2M1NGC",
  gtm_id: "GTM-NQJBBF9",
  twitter: "NewsSouthHams",
  facebook: "DartChronicle",
  pub: "Dartmouth Chronicle",

  web: "dartmouth-today.co.uk",
  url: "www.dartmouth-today.co.uk",
  area: "",
  media_force: "/154725070,22694784101/southhams-today",
  region: gaRegions["devon"] || "",

  contact: `Tindle House,
101-103 Fore Street,
Kingsbridge,
Devon, England,
TQ7 1DA
Tel: 01548 856353
Email: tom.ladds@southhams-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=77",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=78",
  },
  page_suite: {
    publication_name: "Dartmouth Chronicle",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Dartmouth Chronicle - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Dartmouth and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ6 9RY",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const TeignmouthPost = {
  advertising_links: {
    tel: "01626 355566 ",
    email: "sales@middevonadvertiser.co.uk",
  },
  jobs_number: "01626 355566",
  your_story: "newsdesk@southhams-today.co.uk",
  public_notices: "http://southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Teignmouth Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
    },
    {
      PublicationName: "Dawlish Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
    },
    {
      PublicationName: "Homebuyer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=3409518d-edc4-4e90-b6f7-12e673aaea43",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=3409518d-edc4-4e90-b6f7-12e673aaea43",
    },
    {
      PublicationName: "Newton Abbot Mid-Devon Advertiser",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
    },
  ],

  special_editions: [
    {
      PublicationName: "Bovey Tracey Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A44338/boveytracey/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252F",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/7229/85b35029f/thumb/page1.jpg",
    },
    {
      PublicationName: "Christmas In Newton Abbot",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43h6w/christmas18/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3937422%252Fchristmas-18%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/7422/deff5b107/thumb/page1.jpg",
    },
    {
      PublicationName: "Kingsteignton Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43q93/kingsteigntonTownGui/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941957%252Fkingsteignton-Town-Guide%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1957/d6f319a91/thumb/page1.jpg",
    },

    {
      PublicationName: "Newton Abbot Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43aae/NAtownguide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3934148%252FNA-town-guide",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/4148/19c57c36e/thumb/page1.jpg",
    },
    {
      PublicationName: "Queen's 90th Birthday",
      PublicationLink:
        "http://content.yudu.com/Library/A4028b/queensbirthday/resources/index.htm?referrerUrl=http%3A%2F%2Ffree.yudu.com%2Fitem%2Fdetails%2F3766686%2Fqueens-birthday%3Fedit_mode%3Don",
      PublicationThumb:
        "http://images.yudu.com/item_thumbnail/376/6686/a8ebd8be4/thumb/page1.jpg",
    },
    {
      PublicationName: "Striding Out Bovey Tracey",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43pn3/StridingOut2019/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941650%252FStriding-Out-2019%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1650/feb1f9ca0/thumb/page1.jpg",
    },
    {
      PublicationName: "Times & Diary",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ksq/TTD98/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3939329%252FTTD-98%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/9329/a788ce59f/thumb/page1.jpg",
    },
  ],
  publication: "teignmouth-today.co.uk",
  ga_tracking_id: "UA-65082210-1",
  g4_tracking_id: "G-MNZMY9P6H2",
  gtm_id: "GTM-PT6FJ93",
  twitter: "mdaupdatess",
  facebook: "MidDevonAdvertiser",
  pub: "Teignmouth Post",
  region: gaRegions["devon"] || "",

  web: "teignmouth-today.co.uk",
  url: "www.teignmouth-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/middevonadvertiser",
  contact: `Invertere Buildings,
47 Courtenay Street,
Newton Abbot,
Devon, England,
TQ12 2QN
Tel: 01626 353555
Email: news@middevonadvertiser.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=38",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=39",
  },
  page_suite: {
    publication_name: "Teignmouth Post",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Teignmouth Post- local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Teignmouth and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ14 9DF",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const DawlishGazette = {
  advertising_links: {
    tel: "01626 355566 ",
    email: "lianne.hunt@middevonadvertiser.co.uk",
  },
  jobs_number: "01626 355566",
  your_story: "news@middevonadvertiser.co.uk",
  public_notices: "southwest.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Dawlish Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
    },
    {
      PublicationName: "Homebuyer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=3409518d-edc4-4e90-b6f7-12e673aaea43",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=3409518d-edc4-4e90-b6f7-12e673aaea43",
    },
    {
      PublicationName: "Newton Abbot Mid-Devon Advertiser",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
    },
    {
      PublicationName: "Teignmouth Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
    },
  ],
  special_editions: [
    {
      PublicationName: "Bovey Tracey Town Guide",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/7229/85b35029f/thumb/page1.jpg",
    },
    {
      PublicationName: "Christmas In Newton Abbot",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43h6w/christmas18/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3937422%252Fchristmas-18%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/7422/deff5b107/thumb/page1.jpg",
    },
    {
      PublicationName: "Kingsteignton Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43q93/kingsteigntonTownGui/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941957%252Fkingsteignton-Town-Guide%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1957/d6f319a91/thumb/page1.jpg",
    },

    {
      PublicationName: "Newton Abbot Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43aae/NAtownguide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3934148%252FNA-town-guide",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/4148/19c57c36e/thumb/page1.jpg",
    },
    {
      PublicationName: "Queen's 90th Birthday",
      PublicationLink:
        "http://content.yudu.com/Library/A4028b/queensbirthday/resources/index.htm?referrerUrl=http%3A%2F%2Ffree.yudu.com%2Fitem%2Fdetails%2F3766686%2Fqueens-birthday%3Fedit_mode%3Don",
      PublicationThumb:
        "http://images.yudu.com/item_thumbnail/376/6686/a8ebd8be4/thumb/page1.jpg",
    },
    {
      PublicationName: "Striding Out Bovey Tracey",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43pn3/StridingOut2019/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941650%252FStriding-Out-2019%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1650/feb1f9ca0/thumb/page1.jpg",
    },
    {
      PublicationName: "Times & Diary",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ksq/TTD98/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3939329%252FTTD-98%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/9329/a788ce59f/thumb/page1.jpg",
    },
  ],
  publication: "dawlish-today.co.uk",
  ga_tracking_id: "UA-65082210-1",
  g4_tracking_id: "G-9VM935W3LP",
  gtm_id: "GTM-NJNNMJZ",
  twitter: "dawlishgaz",
  facebook: "MidDevonAdvertiser",
  pub: "www.dawlish-today.co.uk",
  region: gaRegions["devon"] || "",

  web: "dawlish-today.co.uk",
  url: "www.dawlish-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/middevonadvertiser",
  contact: `Invertere Buildings,
47 Courtenay Street,
Newton Abbot,
Devon, England,
TQ12 2QN
Tel: 01626 353555
Email: news@middevonadvertiser.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=38",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=39",
  },
  page_suite: {
    publication_name: "The Ross Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "Dawlish Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Dawlish and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "EX7 9AP",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const MidDevonAdvertiser = {
  advertising_links: {
    tel: "01626 355566 ",
    email: "sales@middevonadvertiser.co.uk",
  },
  jobs_number: "01626 355566",
  your_story: "news@middevonadvertiser.co.uk",
  public_notices: "https://publicnoticeportal.uk/mid-devon-advertiser-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  publications: [
    {
      PublicationName: "Mid Devon Advertiser",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=1da842e3-0788-4c0d-a5c5-d64025fd8574",
    },
    {
      PublicationName: "Homebuyer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=3409518d-edc4-4e90-b6f7-12e673aaea43",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=3409518d-edc4-4e90-b6f7-12e673aaea43",
    },
    {
      PublicationName: "Dawlish Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=15b53252-fe77-48c6-a8c9-c49e2d79b8ef",
    },
    {
      PublicationName: "Teignmouth Post",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=2839ad2e-510a-44b9-8ed7-ffc98def67ab",
    },
  ],
  special_editions: [
    {
      PublicationName: "Bovey Tracey Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A44338/boveytracey/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252F",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/7229/85b35029f/thumb/page1.jpg",
    },
    {
      PublicationName: "Christmas In Newton Abbot",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43h6w/christmas18/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3937422%252Fchristmas-18%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/7422/deff5b107/thumb/page1.jpg",
    },
    {
      PublicationName: "Kingsteignton Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43q93/kingsteigntonTownGui/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941957%252Fkingsteignton-Town-Guide%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1957/d6f319a91/thumb/page1.jpg",
    },
    {
      PublicationName: "Newton Abbot Town Guide",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43aae/NAtownguide/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3934148%252FNA-town-guide",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/4148/19c57c36e/thumb/page1.jpg",
    },
    {
      PublicationName: "Queen's 90th Birthday",
      PublicationLink:
        "http://content.yudu.com/Library/A4028b/queensbirthday/resources/index.htm?referrerUrl=http%3A%2F%2Ffree.yudu.com%2Fitem%2Fdetails%2F3766686%2Fqueens-birthday%3Fedit_mode%3Don",
      PublicationThumb:
        "http://images.yudu.com/item_thumbnail/376/6686/a8ebd8be4/thumb/page1.jpg",
    },
    {
      PublicationName: "Striding Out Bovey Tracey",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43pn3/StridingOut2019/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3941650%252FStriding-Out-2019%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/1650/feb1f9ca0/thumb/page1.jpg",
    },
    {
      PublicationName: "Times & Diary",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43ksq/TTD98/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3939329%252FTTD-98%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/393/9329/a788ce59f/thumb/page1.jpg",
    },
  ],
  publication: "middevonadvertiser.co.uk",
  ga_tracking_id: "UA-65082210-1",
  g4_tracking_id: "G-HMCS4T9EJL",
  gtm_id: "GTM-M9HQP5K",
  twitter: "mdaupdates",
  facebook: "MidDevonAdvertiser",
  pub: "Mid Devon Advertiser",
  region: gaRegions["devon"] || "",

  web: "middevonadvertiser.co.uk",
  url: "www.middevonadvertiser.co.uk",
  area: "",
  media_force: "/154725070,22694784101/middevonadvertiser",
  contact: `Invertere Buildings,
47 Courtenay Street,
Newton Abbot,
Devon, England,
TQ12 2QN
Tel: 01626 353555
Email: news@middevonadvertiser.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=38",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=39",
  },
  page_suite: {
    publication_name: "Mid devon advertiser",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Mid Devon Advertiser - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Newton Abbot, Mid Devon and your local area.",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "TQ12 1NE",
  mail_chimp: "",

  quantcast: "p-uGUachD16z0h6",
};
export const tavistockToday = {
  advertising_links: {
    tel: "01822 613666  ",
    email: "lianne.hunt@tavistock-today.co.uk",
  },
  jobs_number: "01626 355566",
  public_notices: "https://publicnoticeportal.uk/tavistock-times-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_devon-uk/browse",
  your_story: "editorial@tavistock-today.co.uk",
  publications: [
    {
      PublicationName: "Tavistock Times Gazette",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=f168a8f3-434e-4b53-bec5-f5e6786ea452",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=f168a8f3-434e-4b53-bec5-f5e6786ea452",
    },
    {
      PublicationName: "Okehampton Times",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6c536098-f93f-4533-a7a9-19e8056760ed",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=6c536098-f93f-4533-a7a9-19e8056760ed",
    },
  ],
  special_editions: null,
  publication: "tavistock-today.co.uk",
  ga_tracking_id: "UA-65128205-1",
  g4_tracking_id: "G-LJ9R820SVN",
  gtm_id: "GTM-KKTPFXT",
  twitter: "tavistocktimes",
  facebook: "tavistock.times",
  pub: "Tavistock Times Gazette",

  web: "tavistock-today.co.uk",
  url: "www.tavistock-today.co.uk/",
  area: "",
  media_force: "/154725070,22694784101/tavistock-today",
  region: gaRegions["devon"] || "",

  contact: `42 Brook Street,
Tavistock,
Devon, England,
PL19 0HE
Tel: 01822 613666
Email: editorial@tavistock-today.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dEssex%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=83",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=84",
  },
  page_suite: {
    publication_name: "Tavistock Times Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo:
    "Tavistock Times Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Tavistock and your local area.",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "PL19 0HD",
  mail_chimp: "",
  quantcast: "p-uGUachD16z0h6",
};

//wales

export const tenbyTodayConfig = {
  advertising_links: {
    tel: "01834 843262",
    email: "advertising@tenby-today.co.uk",
  },
  jobs_number: "01834 843262",
  public_notices: "https://publicnoticeportal.uk/tenby-observer-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Tenby Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=a541dc41-72dc-4b47-bd05-4f65e526817a",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=a541dc41-72dc-4b47-bd05-4f65e526817a",
    },
    {
      PublicationName: "Pembroke and Pembroke Dock Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bc8d046d-7cb8-419c-809c-0154aaa56015",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=bc8d046d-7cb8-419c-809c-0154aaa56015",
    },
    {
      PublicationName: "Narberth and Whitland Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=9372f8cb-b90f-48a5-b86b-19bd8736637c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=9372f8cb-b90f-48a5-b86b-19bd8736637c",
    },
  ],
  special_editions: [
    {
      PublicationName: "Tenby Ironman",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qo2/TenbyIronman/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942138%252FTenby-Ironman%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2138/0b20e4fcf/thumb/page1.jpg",
    },
    {
      PublicationName: "Tenby Summer Guide",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6479bd38-7e4c-4f16-9168-e509b56edabc",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=6479bd38-7e4c-4f16-9168-e509b56edabc",
    },
    {
      PublicationName: "Wedding Planner",
      PublicationLink:
        "https://content.yudu.com/web/17nyu/WeddingDays/WPSpring25/index.html",
      PublicationThumb:
        "https://p18.zdusercontent.com/attachment/20275793/nT0uath4kn3h8EkMZdc4HrPgC?token=eyJhbGciOiJkaXIiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2In0..3ZIyYeLYFKacGHdtr6jr2w.EtumVPeCInoCkETYoX3bVsIK1eeBYBPc1m7IW8n6pyf1CXOPc2K0Hs-k29mWj4JsEe6sIDd4c61jLPAejCL_0MPS9AzuvyZgucaJ2qL5mZ4bwhufT4sS91kcA7yb0pxk8XRIWuwBsrdYIRdmXPTqIosRwpKXtyBdu3d3O-JE-olyR0zCov1smIyEaZbG5HRVUAbNrN1E1H7zv-z_naFV7-bADAraNkcXi5rWVba7Rp5ZopyBO-86xoliZDLHMxeR-vXZ7PphQKcN17Trn6HwgrUypX9KBAPMwjz9DvLYqfc.-7UBwr0i54J-D64Zp9L_cg",
    },
  ],
  publication: "tenby-today.co.uk",
  ga_tracking_id: "UA-65128025-1",
  g4_tracking_id: "G-192B1QLSYF",
  gtm_id: "GTM-PPWHDPK",
  twitter: "tenbyobserver",
  media_force: "/154725070,22694784101/tenby-today",
  contact: `Tindle House,
Warren Street,
Tenby,
Pembrokeshire, Wales,
SA70 7JY,
Tel: 01834 843262,
Email: paul.evans@tenby-today.co.uk`,
  ////154725070/tenby-today/
  facebook: "TenbyObserver/",
  region: gaRegions["wales"] || "",

  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dPembrokeshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=86",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=87",
  },
  page_suite: {
    publication_name: "Tenby Observer",
    Publication_link:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=a541dc41-72dc-4b47-bd05-4f65e526817a",
    Publication_thumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=a541dc41-72dc-4b47-bd05-4f65e526817a",
  },
  pub: "Tenby Observer",
  your_story: "reporters@tenby-today.co.uk",
  web: "tenby-today.co.uk",
  url: "www.tenby-today.co.uk",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  default_Seo: "Tenby Observer - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Tenby and your local area.",

  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};

export const narberthAndWhitlandTodayConfig = {
  advertising_links: {
    tel: "01834 843262 ",
    email: "advertising@tenby-today.co.uk",
  },
  jobs_number: "01834 843262",
  public_notices: "https://wales.epublicnotices.co.uk/",
  region: gaRegions["wales"] || "",

  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Narberth and Whitland Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=9372f8cb-b90f-48a5-b86b-19bd8736637c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=9372f8cb-b90f-48a5-b86b-19bd8736637c",
    },
    {
      PublicationName: "Pembroke and Pembroke Dock Observer",
      PublicationLink:
        "https://subscriber.pagesuite-professional.co.uk/subscribe.aspx?source=4&eid=542c8da4-5e24-4b48-bb82-1fddcae9ebc6",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=bc8d046d-7cb8-419c-809c-0154aaa56015",
    },
    {
      PublicationName: "Tenby Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=a541dc41-72dc-4b47-bd05-4f65e526817a",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=a541dc41-72dc-4b47-bd05-4f65e526817a",
    },
  ],
  special_editions: [
    {
      PublicationName: "Tenby Ironman",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qo2/TenbyIronman/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942138%252FTenby-Ironman%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2138/0b20e4fcf/thumb/page1.jpg",
    },
    {
      PublicationName: "Tenby Summer Guide",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6479bd38-7e4c-4f16-9168-e509b56edabc",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=6479bd38-7e4c-4f16-9168-e509b56edabc",
    },
    {
      PublicationName: "Wedding Planner",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43wnp/tenbyweddings20/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3944743%252Ftenby-weddings-20%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/4743/a1f117f7b/thumb/page1.jpg",
    },
  ],
  publication: "narberth-and-whitland-today.co.uk",
  ga_tracking_id: "UA-65128025-1",
  g4_tracking_id: "G-76C9FTX9WR",
  gtm_id: "GTM-PB29L9N",
  twitter: "",
  facebook: "",
  pub: "Narberth & Whitland Observer",
  web: "narberth-and-whitland-today.co.uk",
  media_force: "/154725070,22694784101/tenby-today",
  url: "https://test.co.uk/",
  google_search: "test",
  area: "Fltonherald",
  contact: `Tindle House,
  Warren Street,
  Tenby,
  Pembrokeshire, Wales,
  SA70 7JY,
  Tel: 01834 843262,
  Email: paul.evans@tenby-today.co.uk`,
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dPembrokeshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=86",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=87",
  },

  your_story: "reporters@tenby-today.co.uk",
  default_Seo:
    "Narberth & Whitland Observer - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Narberth, Whitland and your local area.",
  page_suite: {
    publication_name: "Narberth and Whitland Observer",
    Publication_link:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=9372f8cb-b90f-48a5-b86b-19bd8736637c",
    Publication_thumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=9372f8cb-b90f-48a5-b86b-19bd8736637c",
  },
};

export const theforesterConfig = {
  advertising_links: {
    tel: "01594 820600",
    email: "adverts@theforester.co.uk",
  },
  jobs_number: "01594 820600",
  public_notices: "https://publicnoticeportal.uk/the-forester",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "The Forester",
      PublicationLink:
        " http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2b1fc125-e5fd-4fb1-bf81-ea54727d6ce4",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=2b1fc125-e5fd-4fb1-bf81-ea54727d6ce4",
    },
  ],
  special_editions: null,
  publication: "theforester.co.uk",
  ga_tracking_id: "UA-65131214-1",
  g4_tracking_id: "G-W0KSM60V73",
  gtm_id: "GTM-KP49TBH",
  twitter: "theforesternews",
  facebook: "ForesterNewspaper",
  region: gaRegions["herefordshire"] || "",

  pub: "The Forester",
  media_force: "/154725070,22694784101/the_forester",
  contact: `The Tindle Suite,
  Kings Buildings,
  Hill Street, Lydney,
  Gloucestershire, UK,
  GL15 5HE,
  Tel: 01594 820600,
  Email: editorial@theforester.co.uk`,

  web: "theforester.co.uk",
  url: "https://www.theforester.co.uk",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dGloucestershire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=89",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=88",
  },
  page_suite: {
    publication_name: "The Forester",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=2b1fc125-e5fd-4fb1-bf81-ea54727d6ce4",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=2b1fc125-e5fd-4fb1-bf81-ea54727d6ce4",
  },
  your_story: "news@theforester.co.uk",
  default_Seo: "The Forester - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Forest of Dean, Wye Valley and your local area.",

  //
};

export const rossgazetteConfig = {
  advertising_links: {
    tel: "01989 562007",
    email: "sales@rossgazette.com",
  },
  jobs_number: "01989 562007",
  public_notices: "https://publicnoticeportal.uk/the-ross-gazette",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "The Ross Gazette",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
    },
  ],
  special_editions: null,
  publication: "rossgazette.com",
  ga_tracking_id: "UA-65132312-1",
  g4_tracking_id: "G-YPXL146B6B",
  gtm_id: "GTM-KZP533D",
  twitter: "RossGazette",
  facebook: "TheRossGazette",
  your_story: "editorial@rossgazette.com",
  pub: "The Ross Gazette",
  region: gaRegions["herefordshire"] || "",

  web: "rossgazette.com",
  url: "https://www.rossgazette.com/",
  google_search: "test",
  area: "Fltonherald",
  media_force: "/154725070,22694784101/rossgazette",
  contact: `The Tindle Suite,
  Kings Buildings,
  Hill Street,
  Lydney,
  Gloucestershire,
  UK,
  GL15 5HE,
  Tel: 01594 820600,
  Email: editorial@theforester.co.uk`,
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dGloucestershire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=74",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=75",
  },
  page_suite: {
    publication_name: "The Ross Gazette",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=feb54910-5085-44af-8e6c-9f405f9027fc",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=feb54910-5085-44af-8e6c-9f405f9027fc",
  },
  default_Seo: "The Ross Gazette - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Ross and your local area..",

  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};

export const theforestreviewConfig = {
  advertising_links: {
    tel: "01594 841113",
    email: "ads@theforestreview.co.uk",
  },
  jobs_number: "01594 841113",
  public_notices:
    "https://publicnoticeportal.uk/forest-of-dean-and-wye-valley-review",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Forest of Dean and Wye Valley Review",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=dc43da64-0fec-4776-a517-22780c4ae1fd",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=dc43da64-0fec-4776-a517-22780c4ae1fd",
    },
  ],
  region: gaRegions["herefordshire"] || "",

  special_editions: null,
  publication: "theforestreview.co.uk/",
  ga_tracking_id: "UA-65069172-1",
  g4_tracking_id: "G-3DZ6XFFCGX",
  gtm_id: "GTM-MFKDPMD",
  twitter: "DeanWyeNews",
  facebook: "theforestreview",
  pub: "Forest of Dean & Wye Valley Review ",
  web: "theforestreview.co.uk/",

  url: "https://www.theforestreview.co.uk/",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dGloucestershire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=50",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=51",
  },

  page_suite: {
    publication_name: "Forest of Dean and Wye Valley Review",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=dc43da64-0fec-4776-a517-22780c4ae1fd",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=dc43da64-0fec-4776-a517-22780c4ae1fd",
  },
  google_search: "test",

  your_story: "news@theforester.co.uk",
  contact: `The Tindle Suite, Kings Buildings,
Hill Street,
Lydney,
Gloucestershire, England,
GL15 5HE
Tel: 01594 841113
Email: marcus.law@tindlenews.co.uk`,
  media_force: "/154725070,22694784101/theforestreview",
  default_Seo:
    "Forest of Dean & Wye Valley Review - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Forest of Dean, Wye Valley and your local area.",

  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};

export const pembrokeTodayConfig = {
  advertising_links: {
    tel: "01834 843262 ",
    email: "advertising@tenby-today.co.uk",
  },
  jobs_number: "01834 843262",
  region: gaRegions["wales"] || "",

  public_notices: "https://wales.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Pembroke and Pembroke Dock Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bc8d046d-7cb8-419c-809c-0154aaa56015",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=bc8d046d-7cb8-419c-809c-0154aaa56015",
    },
    {
      PublicationName: "Tenby Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=a541dc41-72dc-4b47-bd05-4f65e526817a",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=a541dc41-72dc-4b47-bd05-4f65e526817a",
    },
    {
      PublicationName: "Narberth and Whitland Observer",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=9372f8cb-b90f-48a5-b86b-19bd8736637c",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=9372f8cb-b90f-48a5-b86b-19bd8736637c",
    },
  ],

  special_editions: [
    {
      PublicationName: "Tenby Ironman",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43qo2/TenbyIronman/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3942138%252FTenby-Ironman%253Fedit_mode%253Don",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/2138/0b20e4fcf/thumb/page1.jpg",
    },
    {
      PublicationName: "Tenby Summer Guide",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=6479bd38-7e4c-4f16-9168-e509b56edabc",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=6479bd38-7e4c-4f16-9168-e509b56edabc",
    },
    {
      PublicationName: "Wedding Planner",
      PublicationLink:
        "https://content.yudu.com/libraryHtml/A43wnp/tenbyweddings20/reader.html?refUrl=https%253A%252F%252Fexpress.yudu.com%252Fitem%252Fdetails%252F3944743%252Ftenby-weddings-20%253Frefid%253D4072",
      PublicationThumb:
        "https://images.yudu.com/item_thumbnail/394/4743/a1f117f7b/thumb/page1.jpg",
    },
  ],
  publication: "pembroke-today.co.uk/",
  ga_tracking_id: "UA-65128025-1",
  g4_tracking_id: "G-E3TJLJST25",
  gtm_id: "GTM-5T4NWDN",
  twitter: "",
  facebook: "",
  media_force: "/154725070,22694784101/tenby-today",
  contact: `Tindle House,
Warren Street,
Tenby,
Pembrokeshire, Wales,
SA70 7JY,
Tel: 01834 843262,
Email: paul.evans@tenby-today.co.uk`,
  pub: "Pembroke And Pembroke Dock Observer",
  web: "http://www.pembroke-today.co.uk/",
  url: "http://www.pembroke-today.co.uk/",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  your_story: "reporters@tenby-today.co.uk",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=CountryIds%3d225%26RegionIDs%3d953%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=86",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=87",
  },
  page_suite: {
    publication_name: "Pembroke and Pembroke Dock Observer",
    Publication_link:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=bc8d046d-7cb8-419c-809c-0154aaa56015",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=bc8d046d-7cb8-419c-809c-0154aaa56015",
  },

  default_Seo:
    "Pembroke And Pembroke Dock Observer - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Pembroke and your local area.",

  //
};
export const monmouthshirebeaconConfig = {
  advertising_links: {
    tel: "01600 712142",
    email: "nigel.m@monmouthshirebeacon.co.uk",
  },
  region: gaRegions["wales"] || "",

  jobs_number: "01600 712142",
  public_notices: "https://publicnoticeportal.uk/monmouthshire-beacon",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Monmouthshire Beacon",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=c9516541-138f-4ff8-9a1a-390afc81cf3c",
      PublicationThumb:
        " http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=c9516541-138f-4ff8-9a1a-390afc81cf3c",
    },
  ],
  special_editions: null,
  publication: "monmouthshirebeacon.co.uk",
  ga_tracking_id: "UA-65071577-1",
  g4_tracking_id: "G-F5PQM9XPCH",
  gtm_id: "GTM-TRC77F9",
  pub: "Monmouthshire Beacon",
  web: "monmouthshirebeacon.co.uk",
  url: "www.monmouthshirebeacon.co.uk",
  google_search: "test",
  area: "Monmouthshire Beacon",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "NP253XJ",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  media_force: "/154725070,22694784101/monmouthshirebeacon",
  contact: `Cornwall House,
  56 Monnow Street,
  Monmouth,
  Monmouthshire, Wales,
  NP25 3XJ,
  Tel: 01600 712142,
  Email: news@monmouthshirebeacon.co.uk`,

  twitter: "monbeacon",
  facebook: "MonmouthshireBeacon/",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dMonmouthshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=2",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=3",
  },
  page_suite: {
    publication_name: "Abergavenny Chronicle",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?referral=mypagesuite&refresh=g19ETi03z16Q&PBID=7a617022-c186-45fe-9da4-bb032182a6a4&skip=",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=7a617022-c186-45fe-9da4-bb032182a6a4",
  },
  your_story: "news@monmouthshirebeacon.co.uk",
  default_Seo:
    "Monmouthshire Beacon - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Monmouthshire and your local area.",

  advertising: "abergavenny_chronicle",
};

export const chepstowbeacon = {
  advertising_links: {
    tel: "01600 712142",
    email: "ads@chepstowbeacon.co.uk",
  },
  region: gaRegions["wales"] || "",

  jobs_number: "01600 712142",
  public_notices: "https://wales.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Monmouthshire Beacon",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=c9516541-138f-4ff8-9a1a-390afc81cf3c",
      PublicationThumb:
        " http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=c9516541-138f-4ff8-9a1a-390afc81cf3c",
    },
  ],
  special_editions: null,
  publication: "farnhamherald.com",
  ga_tracking_id: "UA-65071577-1",
  g4_tracking_id: "G-YZVZ6PHEXF",
  gtm_id: "GTM-NMKHMRG",
  twitter: "chepbeacon",
  facebook: "ChepstowBeacon",
  pub: "Chepstow Beacon",
  web: "chepstowbeacon.co.uk",
  url: "www.chepstowbeacon.co.uk/",
  google_search: "test",
  area: "Chepstow",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "NP16",
  media_force: "/154725070,22694784101/monmouthshirebeacon",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dMonmouthshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=65",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=66",
  },
  page_suite: {
    publication_name: "Monmouthshire Beacon",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=c9516541-138f-4ff8-9a1a-390afc81cf3c",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=c9516541-138f-4ff8-9a1a-390afc81cf3c",
  },
  your_story: "news@monmouthshirebeacon.co.uk",
  contact: `Cornwall House,
  56 Monnow Street,
  Monmouth,
  Monmouthshire, Wales,
  NP25 3XJ,
  Tel: 01600 712142,
  Email: news@chepstowbeacon.co.uk`,
  default_Seo: "Chepstow Beacon - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Chepstow and your local area.",
};
export const cambrianNewsConfig = {
  advertising_links: {
    tel: "01970 611611",
    email: "advertising@cambrian-news.co.uk",
  },
  region: gaRegions["wales"] || "",

  jobs_number: "01970 611611",
  public_notices: "https://publicnoticeportal.uk/cambrian-news-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Aberystwyth",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=1af77554-f3a4-423d-a644-38f171241154",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=1af77554-f3a4-423d-a644-38f171241154",
    },
    {
      PublicationName: "South Ceredigion",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=c8a7ba7e-2239-4334-87bd-2b668b9cfedd",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=c8a7ba7e-2239-4334-87bd-2b668b9cfedd&date=06/12/2016-19:12",
    },
    {
      PublicationName: "Machynlleth & Llanidloes",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=9a4b42e3-0fb6-41c5-9bd7-f46464abcdbf",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=9a4b42e3-0fb6-41c5-9bd7-f46464abcdbf",
    },
    {
      PublicationName: "Meirionnydd",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=9f3741d3-6cdb-4142-96cf-9476753db1c7",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=9f3741d3-6cdb-4142-96cf-9476753db1c7&date=30/11/2016-18:53",
    },
    {
      PublicationName: "Arfon & Dwyfor",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=87a39680-9923-40a5-aaf1-9314bde14068",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=87a39680-9923-40a5-aaf1-9314bde14068&date=30/11/2016-18:53",
    },
  ],

  special_editions: [
    {
      PublicationName: "Cambrian News Business Directory",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=db2216e6-af42-4244-a552-f6a13e8c3dba",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=db2216e6-af42-4244-a552-f6a13e8c3dba&date=05/09/2018-19:13",
    },
    {
      PublicationName: "Celebrating Christmas",
      PublicationLink:
        "https://edition.pagesuite.com/Launch.aspx?PBID=b1478e16-47af-44d6-989f-d04693986dfd",
      PublicationThumb:
        "https://edition.pagesuite.com/get_image.aspx?newportal=true&w=120&pbid=b1478e16-47af-44d6-989f-d04693986dfd",
    },
    {
      PublicationName: "Christmas (Ceredigion)",
      PublicationLink:
        "https://edition.pagesuite.com/Launch.aspx?PBID=1afb38fc-2f82-4f6e-b845-f170f6ade91b",
      PublicationThumb:
        "https://edition.pagesuite.com/get_image.aspx?newportal=true&w=120&pbid=1afb38fc-2f82-4f6e-b845-f170f6ade91b",
    },
    {
      PublicationName: "Christmas (Gwynedd)",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=a4d9fc92-8e4f-4ccc-bf51-5693d5c02c40",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=a4d9fc92-8e4f-4ccc-bf51-5693d5c02c40&date=06/12/2017-15:46",
    },
    {
      PublicationName: "Green Life",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=02e4b8e4-bb5b-467e-8e2e-b09ff7d71320",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=02e4b8e4-bb5b-467e-8e2e-b09ff7d71320&date=03/07/2019-16:17",
    },
    {
      PublicationName: "Holidaymaker",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=de444168-3098-455f-b6d5-b0d820c9ac93",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=de444168-3098-455f-b6d5-b0d820c9ac93",
    },
    {
      PublicationName: "Homes",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=39b8e7df-7ee9-4cfc-aacd-d06b6e516f90",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=39b8e7df-7ee9-4cfc-aacd-d06b6e516f90",
    },
    {
      PublicationName: "New Reg",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=c4a22a3a-d7ef-4d0b-b705-7a1cb36d47c1",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=c4a22a3a-d7ef-4d0b-b705-7a1cb36d47c1&date=15/02/2017-13:12",
    },
    {
      PublicationName: "Out and About",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=14627490-018b-4134-99f8-1364fc312bd1",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&newportal=true&pbid=14627490-018b-4134-99f8-1364fc312bd1",
    },
    {
      PublicationName: "Spring Farming",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk//launch.aspx?pbid=61007702-3d9e-4038-b0cf-71da5919a058",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=61007702-3d9e-4038-b0cf-71da5919a058&date=23/03/2017-12:22",
    },
    {
      PublicationName: "Winter on Wheels",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=f2014e3a-e88b-4404-a3ef-28c10a5548fc",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk//get_image.aspx?w=180&pbid=f2014e3a-e88b-4404-a3ef-28c10a5548fc",
    },
  ],

  publication: "cambrian-news.co.uk",
  ga_tracking_id: "UA-69989439-1",
  g4_tracking_id: "G-8HRNT1NFPQ",
  gtm_id: "GTM-NWBC3MF",
  poool_app_id: "3C4YF-64XQX-KAPGT-8O8BI",
  twitter: "cambriannews",
  facebook: "CambrianNews",
  pub: "Cambrian News",

  web: "cambrian-news.co.uk",
  url: "https://www.cambrian-news.co.uk",
  google_search: "test",
  media_force: "/154725070,22694784101/cambrian-news",
  contact: `Unit 7,
  Cefn Llan Science Park,
  Aberystwyth,
  Ceredigion, Wales,
  SY23 3AH,
  Tel: 01970 615000,
  Email: edit@cambrian-news.co.uk`,
  area: "Fltonherald",
  your_story: "edit@cambrian-news.co.uk",
  default_Seo: "Cambrian News - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Wales and your local area.",

  //
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dGwynedd%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=5",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=6",
  },
  page_suite: {
    publication_name: "Abergavenny Chronicle",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?referral=mypagesuite&refresh=g19ETi03z16Q&PBID=7a617022-c186-45fe-9da4-bb032182a6a4&skip=",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=7a617022-c186-45fe-9da4-bb032182a6a4",
  },
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};
export const breconradnorConfig = {
  advertising_links: {
    tel: "01874 610111",
    email: "sales@brecon-radnor.co.uk",
  },
  jobs_number: "01874 610111",
  public_notices: "https://publicnoticeportal.uk/brecon-and-radnor-express",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Brecon & Radnor Express - Brecon",
      PublicationLink:
        "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=0a92f3aa-9674-489a-96d0-edeb69a7951b",
      PublicationThumb:
        "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=0a92f3aa-9674-489a-96d0-edeb69a7951b",
    },
  ],
  special_editions: null,
  publication: "brecon-radnor.co.uk",
  ga_tracking_id: "UA-65062885-1",
  g4_tracking_id: "G-DZSD0F1JZS",
  gtm_id: "GTM-WBP8LX2",
  twitter: "BnRExpress",
  facebook: "BreconRadnorExpress",
  pub: "Brecon & Radnor Express",
  web: "haslemereherald.com",
  url: "www.brecon-radnor.co.uk",
  media_force: "/154725070,22694784101/brecon-radnor",
  google_search: "test",
  region: gaRegions["wales"] || "",

  contact: `11 The Bulwark,
  Brecon,
  Powys, Wales,
  LD3 7AE,
  Tel: 01874 610111,
  Email: theeditor@brecon-radnor.co.uk`,
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  your_story: "theeditor@brecon-radnor.co.uk",
  default_Seo:
    "Brecon & Radnor Express - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Brecon, Radnor and your local area.",

  quantcast: "p-uGUachD16z0h6",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=CountryIds%3d225%26RegionIDs%3d953%26FeaturedOnly%3d1",
    Recruiters: "https://www.new-job-today.co.uk/widget/recruiters/;i=17",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=18",
  },
  page_suite: {
    publication_name: "Brecon & Radnor Express - Brecon",
    Publication_link:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=0a92f3aa-9674-489a-96d0-edeb69a7951b",
    Publication_thumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=0a92f3aa-9674-489a-96d0-edeb69a7951b",
  },
};

export const abergavennychronicleConfig = {
  advertising_links: {
    tel: "01873 852187",
    email: "Andrew.hart@abergavennychronicle.com",
  },
  region: gaRegions["wales"] || "",

  jobs_number: "01873 852187",
  public_notices: "https://publicnoticeportal.uk/abergavenny-chronicle",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_wales_and_borders-uk/browse",
  publications: [
    {
      PublicationName: "Abergavenny Chronicle",
      PublicationLink:
        "http://edition.pagesuite-professional.co.uk/Launch.aspx?referral=mypagesuite&refresh=g19ETi03z16Q&PBID=7a617022-c186-45fe-9da4-bb032182a6a4&skip=",
      PublicationThumb:
        "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=7a617022-c186-45fe-9da4-bb032182a6a4",
    },
  ],
  special_editions: null,
  publication: "abergavennychronicle.com",
  media_force: "/154725070,22694784101/abergavenny_chronicle",
  ga_tracking_id: "UA-65065948-1",
  g4_tracking_id: "G-66KCY8GST4",
  gtm_id: "GTM-KHHQRSR",
  twitter: "aberchronicle",
  facebook: "abergavenny.chronicle/",
  jobs: {
    Jobs: "https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dMonmouthshire%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1",
    Recruiters:
      "https://www.new-job-today.co.uk/widget/browsefacets/;i=3 https://www.new-job-today.co.uk/widget/recruiters/;i=2",
    Facets: "https://www.new-job-today.co.uk/widget/browsefacets/;i=3",
  },
  page_suite: {
    publication_name: "Abergavenny Chronicle",
    Publication_link:
      "http://edition.pagesuite-professional.co.uk/Launch.aspx?referral=mypagesuite&refresh=g19ETi03z16Q&PBID=7a617022-c186-45fe-9da4-bb032182a6a4&skip=",
    Publication_thumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=7a617022-c186-45fe-9da4-bb032182a6a4",
  },
  your_story: "news@abergavennychronicle.com",
  default_Seo:
    "Abergavenny Chronicle - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Abergavenny and your local area.",
  pub: "Abergavenny Chronicle",
  web: "abergavennychronicle.com",
  url: "https://www.abergavennychronicle.com",
  google_search: "test",
  area: "Abergavenny",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "NP75EE",
  mail_chimp: "Abergavenny Chronicle",
  advertising: "abergavenny_chronicle",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};

//farnham
const specialEdition = [
  {
    PublicationName: "Which School or College magazine",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=566b4ba1-ec5c-4dec-8dee-a8bd3e3abfb5",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=566b4ba1-ec5c-4dec-8dee-a8bd3e3abfb5",
  },
];
const farnham_publication = [
  {
    PublicationName: "Alton Herald",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=dcda7c81-42ee-4e31-a0fc-e875e075e822",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=dcda7c81-42ee-4e31-a0fc-e875e075e822",
  },
  {
    PublicationName: "Bordon Herald",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=e040771d-f0d5-46d7-bd20-d3e7e391bad5",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?newportal=true&w=120&pbid=e040771d-f0d5-46d7-bd20-d3e7e391bad5",
  },
  {
    PublicationName: "Farnham Herald",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=bffaef50-fc03-49d2-9683-fc6180f9ee76",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=bffaef50-fc03-49d2-9683-fc6180f9ee76",
  },
  {
    PublicationName: "Haslemere Herald",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=d070ac47-2196-4742-a195-648a5868521b",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=d070ac47-2196-4742-a195-648a5868521b",
  },
  {
    PublicationName: "Liphook Herald",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&pubid=546cb3f3-737e-40de-878e-93359f408ca0",
    PublicationThumb:
      "https://edition.pagesuite-professional.co.uk/get_image.aspx?w=190&pbid=546cb3f3-737e-40de-878e-93359f408ca0",
  },
  {
    PublicationName: "Petersfield Post",
    PublicationLink:
      "https://edition.pagesuite-professional.co.uk/Launch.aspx?PBID=84f1f038-48da-49b4-93da-bbe3a2c4e729",
    PublicationThumb:
      "http://edition.pagesuite-professional.co.uk/get_image.aspx?w=120&pbid=84f1f038-48da-49b4-93da-bbe3a2c4e729",
  },
];

export const haslemereheraldConfig = {
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  jobs_number: "01252 725224",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=46",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=48`,
  },
  public_notices: "http://southeast.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  publications: farnham_publication,
  publication: "haslemereherald.com/",
  your_story: "news@farnhamherald.com",
  region: gaRegions["surrey"] || "",

  ga_tracking_id: "UA-65070161-1",
  g4_tracking_id: "G-W9QBRPRKS7",
  gtm_id: "GTM-N4D4TPR",
  twitter: "haslemereh",
  facebook: "haslemereherald",
  pub: "Haslemere Herald",
  web: "haslemereherald.com/",
  url: "https://www.haslemereherald.com/",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  special_editions: specialEdition,

  mail_chimp: "Haslemere Herald",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  media_force: "/154725070,22694784101/farnhamherald",
  default_Seo: "Haslemere Herald - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Haslemere and your local area.",
  contact: `Newspaper House,
  114-115 West Street,
  Farnham,
  Surrey, England,
  GU9 7HL, Tel: 01252 725224, news@farnhamherald.com`,
};

export const liphookheraldConfig = {
  public_notices: "http://southeast.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  your_story: "news@farnhamherald.com",
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  jobs_number: "01252 725224",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=46",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=48`,
  },
  region: gaRegions["surrey"] || "",
  publication: "liphookherald.com",
  ga_tracking_id: "UA-65070161-1",
  g4_tracking_id: "G-Y2HQXFPHP2",
  gtm_id: "GTM-KMCMD53",
  publications: farnham_publication,
  pub: "Liphook Herald",
  web: "liphookherald.com",
  url: "https://www.liphookherald.com",
  google_search: "test",
  special_editions: specialEdition,

  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Liphook Herald",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  media_force: "/154725070,22694784101/farnhamherald",
  default_Seo: "Liphook Herald - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Liphook and your local area.",
  contact: `Newspaper House,
  114-115 West Street,
  Farnham,
  Surrey, England,
  GU9 7HL, Tel: 01252 725224, news@farnhamherald.com`,
};

export const bordonheraldConfig = {
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  jobs_number: "01252 725224",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=46",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=48`,
  },
  public_notices: "http://southeast.epublicnotices.co.uk/",
  your_story: "news@farnhamherald.com",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  publications: farnham_publication,
  publication: "bordonherald.com/",
  ga_tracking_id: "UA-65070161-1",
  g4_tracking_id: "G-SWE2TPFV14",
  gtm_id: "GTM-M93JX6F",
  twitter: "",
  region: gaRegions["surrey"] || "",
  facebook: "BordonHerald",
  pub: "Bordon Herald",
  web: "https://www.bordonherald.com/",
  url: "https://www.bordonherald.com/",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Bordon Herald",
  advertising: "farnhamherald",
  state: "prod",
  special_editions: specialEdition,

  quantcast: "p-uGUachD16z0h6",
  media_force: "/154725070,22694784101/farnhamherald",
  default_Seo: "Bordon News - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Bordon and your local area.",
  contact: `Newspaper House,
  114-115 West Street,
  Farnham,
  Surrey, England,
  GU9 7HL, Tel: 01252 725224, news@farnhamherald.com`,
};

export const altonheraldConfig = {
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  region: gaRegions["surrey"] || "",
  jobs_number: "01252 725224",
  public_notices: "http://southeast.epublicnotices.co.uk/",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=46",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=48`,
  },
  your_story: "news@farnhamherald.com",
  publication: "altonherald.com/",
  publications: farnham_publication,
  ga_tracking_id: "UA-65070161-1",
  g4_tracking_id: "G-KXLC37QF67",
  gtm_id: "GTM-N2PV2P3",
  twitter: "",
  facebook: "AltonHerald",
  pub: "Alton Herald",
  web: "altonherald.com/",
  url: "https://www.altonherald.com/",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Alton Herald",
  special_editions: specialEdition,
  advertising: "Alton Herald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
  media_force: "/154725070,22694784101/farnhamherald",
  default_Seo: "Alton News - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Alton and your local area.",
};

export const farnhamheraldConfig = {
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  region: gaRegions["surrey"] || "",
  jobs_number: "01252 725224",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=46",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=48`,
  },
  your_story: "news@farnhamherald.com",
  public_notices: "https://publicnoticeportal.uk/farnham-herald-series",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  publications: farnham_publication,
  publication: "farnhamherald.com",
  ga_tracking_id: "UA-65070161-1",
  g4_tracking_id: "G-V7L8HQQJNS",
  gtm_id: "GTM-KLPKWGB",
  twitter: "farnhamherald",
  facebook: "farnhamherald",
  pub: "Farnham Herald",
  web: "farnhamherald.com",
  url: "https://www.farnhamherald.com",
  google_search: "test",
  area: "Fltonherald",
  media_force: "/154725070,22694784101/farnhamherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  special_editions: specialEdition,

  quantcast: "p-uGUachD16z0h6",
  contact: `Newspaper House,
  114-115 West Street,
  Farnham,
  Surrey, England,
  GU9 7HL, Tel: 01252 725224, news@farnhamherald.com`,
  default_Seo: "Farnham News - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Farnham and your local area.",
};

export const petersfieldpostConfig = {
  advertising_links: {
    tel: "01252 725224",
    email: "alex.ashbee@altonherald.com",
  },
  region: gaRegions["surrey"] || "",
  special_editions: specialEdition,
  jobs_number: "01252 725224",
  jobs: {
    Jobs: `https://www.new-job-today.co.uk/_feeds/jobs-xml-2.ashx?params=Location%3dSurrey%26LocationRadius%3d5%26CountryIds%3d225%26FeaturedOnly%3d1`,
    Recruiters: "https://www.new-job-today.co.uk/widget/jobs/;i=13",
    Facets: `https://www.new-job-today.co.uk/widget/browsefacets/;i=15`,
  },
  your_story: "news@farnhamherald.com",
  public_notices: "https://publicnoticeportal.uk/petersfield-post",
  family_anouncements:
    "https://www.legacy.com/uk/obituaries/tindle_surrey_and_hants-uk/browse",
  publications: farnham_publication,
  publication: "petersfieldpost.co.uk",
  ga_tracking_id: "UA-65085804-1",
  g4_tracking_id: "G-QJEGTRM4TV",
  gtm_id: "GTM-5PFBSK7",
  twitter: "postpfield",
  facebook: "petclanhornpost",
  media_force: "/154725070,22694784101/petersfieldpost",
  pub: "Petersfield Post",
  contact: `The Petersfield Post,
  114-115 West Street,
  Farnham,
  Surrey, UK,
  GU9 7HL, Tel: 01252 725224,
   Email: news@petersfieldpost.co.uk`,
  web: "petersfieldpost.co.uk",
  url: "https://www.petersfieldpost.co.uk/",
  google_search: "test",
  area: "Fltonherald",
  weather_key: "83df6c2d419c4b8cb5c111532210710",
  default_Seo: "Petersfield Post - local news at the heart of the community",
  default_desc_seo:
    "Latest news, sport and what’s on in Petersfield and your local area.",
  location: "GU90AB",
  mail_chimp: "Petersfield Post",
  advertising: "farnhamherald",
  state: "prod",
  quantcast: "p-uGUachD16z0h6",
};

export function setTokenCookie(res: any, token: string) {
  const cookie = serialize(TOKEN_NAME, token, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: true,
    secure: process.env.NODE_ENV === "production",
    path: "/",
    sameSite: "lax",
  });
  document.cookie = cookie;
  //  res.setHeader('Set-Cookie', cookie)
}
